<template>
  <v-app>
    <v-app-bar class="container" elevation="1">
      <v-row no-gutters class="align-center">
        <v-icon
          icon="$menu"
          class="v-col-2 d-lg-none d-flex justify-center"
          color="red"
          size="large"
          aria-label="menu"
          @click.stop="drawer = !drawer"
        ></v-icon>

        <v-col cols="6" md="7" lg="3" xl="2" class="d-flex justify-center">
          <ImageLogo />
        </v-col>

        <v-col cols="7" md="7" lg="7" xl="8" class="d-none d-lg-block">
          <Menu
            :menu="page.menu"
            class="d-flex justify-center text-redColor font-weight-bold text-uppercase"
            :nav="true"
            width="100%"
          />
        </v-col>

        <v-col cols="4" md="3" lg="2" xl="2" class="d-flex justify-center">
          <Button :download-nick="nick" :software-title="soft" :text="page.downloadTitle" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary location="top">
      <Menu :menu="page.menu" class="mt-4 text-redColor font-weight-bold text-uppercase" />
    </v-navigation-drawer>
    <v-main v-scroll="onScroll" class="child">
      <aside v-if="page?.blocks?.AboveContent">
        <Region :key="page.uri" region="AboveContent" :blocks="page.blocks.AboveContent" />
      </aside>
      <breadcrumbs
        v-if="page.breadcrumbs && page.breadcrumbs.length > 0"
        :items="page.breadcrumbs"
        devider-icon="$chevronRight"
        devider-color="breadcrumbs"
        class="py-0 ma-auto container"
      />

      <v-row v-if="page.showRightRegion" no-gutters>
        <v-col cols="12" md="9" class="pr-2">
          <article>
            <Article :key="page.uri" class="ma-2 pa-3">
              <template
                v-if="page.typename === 'BrandReview' || page.typename === 'SlotsGameReview'"
                #article-image-button
              >
                <ArticlePassport />
              </template>
              <!-- <template #article-button></template> -->
            </Article>
            <Button
              :download-nick="nick"
              :software-title="soft"
              :text="page.downloadTitle"
              class="d-block ma-auto pa-2 mt-2 text-uppercase font-weight-bold"
            />
          </article>
          <aside v-if="page?.blocks?.BelowContent">
            <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
          </aside>
        </v-col>
        <aside v-if="page?.showRightRegion" class="v-col-12 v-col-md-3">
          <Region
            :key="page.uri"
            region="RightSide"
            :blocks="page.blocks.RightSide"
            class="rightBlock"
            @noregion="page?.hideRegion"
          />
        </aside>
      </v-row>
      <template v-else>
        <article>
          <Article :key="page.uri" class="ma-2 pa-3">
            <template
              v-if="page.typename === 'BrandReview' || page.typename === 'SlotsGameReview'"
              #article-image-button
            >
              <ArticlePassport />
            </template>
            <template #article-button></template>
          </Article>
        </article>
        <aside v-if="page?.blocks?.BelowContent">
          <Region :key="page.uri" region="BelowContent" :blocks="page.blocks.BelowContent" />
        </aside>
      </template>

      <v-footer v-if="page?.blocks?.Footer" class="d-block footer">
        <Region :key="page.uri" region="Footer" :blocks="page.blocks.Footer" />
      </v-footer>
    </v-main>
    <v-sheet v-show="fab" :key="page.uri" class="footer bg-white">
      <snack-bar />
    </v-sheet>
  </v-app>
</template>

<script setup>
import ArticlePassport from "@/components2/lo/casinoofthekings/ArticlePassport";
import SnackBar from "@/components2/lo/casinoofthekings/SnackBar";
const drawer = ref(false);
const drawerIcon = ref("$menu");
const toggleDrawer = () => {
  drawer.value = !drawer.value;
  drawerIcon.value = drawer.value ? "$close" : "$menu";
};
const page = inject("page");
const nick = ref(unref(page)?.rels?.downloadNick);
const soft = ref(unref(page)?.rels?.softwareTitle);

watchEffect(() => {
  nick.value = unref(page)?.rels?.downloadNick;
  soft.value = unref(page)?.rels?.softwareTitle;
});
const fab = ref(false);
const onScroll = e => {
  if (typeof window === "undefined") return;
  const top = window.scrollY || e.target.scrollTop || 0;
  fab.value = top > 20;
};
</script>
